<template>
  <div>
    <Table
      title-key="name"
      :actions="actions"
      :items="$store.state.voucher.tableData"
      :fields="$store.getters['voucher/tableFields']"
      :loading="$store.state.voucher.loadingTable"
      :filter="$store.state.voucher.tableFilter"
      :sort="$store.state.voucher.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      :keyword="$store.state.voucher.tableKeyword"
      :pagination="$store.state.voucher.tablePagination"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />

    <!-- Delete User confirmation modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete Voucher"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteVoucher"
    >
      Are you sure want to delete this data?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      rejectionModalVisible: false,
      refundModalVisible: false,
      deleteModalVisible: false,

      selectedData: '',

      // Tabs
      tabOptions: [
        { key: 'status', label: 'All' },
        {
          key: 'status', label: 'On going', value: 'available', default: true,
        },
        { key: 'status', label: 'Completed', value: 'expired' },
      ],

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: [
        'view',
        'create',
        'update',
        {
          type: 'other',
          children: [
            {
              label: 'View usage',
              event: this.toUsage,
            },
            {
              label: 'Delete',
              event: this.confirmDelete,
              visibility: {
                callback: data => !data.deletedAt && this.canAccess('manage', 'Voucher'),
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    changeFields(val) {
      this.$store.commit('voucher/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('voucher/RESET_TABLE_FIELDS')
    },
    loadData() {
      return this.$store.dispatch('voucher/getTableData')
    },
    filterData(val) {
      this.$store.commit('voucher/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('voucher/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('voucher/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('voucher/SET_TABLE_KEYWORD', val)
    },
    toUsage(data) {
      this.$router.push(`/voucher/${data.item.id}/usage`)
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
    deleteVoucher() {
      this.$store.dispatch('voucher/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The voucher has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
  },
}
</script>
